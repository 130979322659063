import React, { useState } from 'react'
import { Heading, Stack, Text, Container } from '@chakra-ui/react'
import PersonNameGenerator from '../Generators/Names/Person'

const HeaderPerson = ({ names, sex, headerText }) => {
  const [name, setName] = useState('Nombre')
  const [surname, setSurname] = useState('Apellido')
  return (
    <Container maxW="5xl">
      <Stack
        textAlign="center"
        align="center"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight="110%"
          as="span"
        >
          <Text as="span" color="orange.400">
            {name}
          </Text>{' '}
          {surname}
        </Heading>
        <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
          {headerText}
        </Heading>
        <PersonNameGenerator
          setName={setName}
          setSurname={setSurname}
          names={names}
          sex={sex}
        />
      </Stack>
    </Container>
  )
}

export default HeaderPerson
