import React from 'react'
import { Divider, Container } from '@chakra-ui/react'
import Layout from '../base'
import HeaderPersona from '../../components/Header/NameOfPerson'

const NameGeneratorLayout = ({ children, names, sex, headerText }) => (
  <Layout>
    <HeaderPersona names={names} headerText={headerText} sex={sex} />
    <Divider />
    <Container maxW="5xl" my={10}>
      {children}
    </Container>
  </Layout>
)

export default NameGeneratorLayout
