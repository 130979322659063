/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { graphql } from 'gatsby'
import { Center } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../layout/names'
import SEO from '../../components/SEO/PageSEO'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../components/ContentWithStyles/TransformToChakra'

const NameGeneratorPage = ({ location, data }) => (
  <Layout
    names={data.namesJson}
    headerText="Generador de nombres de hombre con apellido al azar"
    sex="male"
  >
    <SEO
      title="Generador de nombres de hombre aleatorios"
      titleSeo="Generador de nombres de hombre aleatorios"
      description="Herramienta para generar nombres de hombre al azar con apellidos en español."
      image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
      // lang="es"
      datePublished="2021-05-11T12:29:33-03:00"
      dateModified="2021-05-11T12:29:33-03:00"
      breadcrumbs={[{ name: 'Nombres', url: '/nombres/' }]}
      permalink={location.pathname}
      // breadcrumbList={[]}
      // noindex={false}
    />
    <Heading1>Generador de nombres de hombre aleatorios</Heading1>
    <Paragraph>
      Con este generador de nombres de hombre aleatorios podr&aacute;s obtener
      muchas opciones de nombres (y apellidos de forma opcional) de forma
      r&aacute;pida y c&oacute;moda, no tendr&aacute;s que perder tiempo
      intentando recordar nombres extra&ntilde;os, o pensando en nombres comunes
      pero diferentes y en gran cantidad. Con esta herramienta online la
      generaci&oacute;n de nombres aleatorios de hombre se volver&aacute;
      trivial y podr&aacute;s centrarte en lo realmente importante, el uso de
      dichos nombres.
    </Paragraph>
    <Center my={5}>
      <StaticImage src="https://placekitten.com/800/600" alt="A kitten" />
    </Center>
    <Paragraph>
      Ya sea que te encuentres buscando el nombre masculino ideal para tu
      beb&eacute;, completando un formulario online con datos ficticios,
      nombrando personas en tu relato o creando personajes para tu partida de
      rol, esta herramienta para generar nombres de ni&ntilde;o al azar te
      permitir&aacute; obtener muchas opciones realmente variadas sin esfuerzo
      alguno.
    </Paragraph>
    <Heading2>
      Nombres de ni&ntilde;o al azar para cualquier prop&oacute;sito
    </Heading2>
    <Paragraph>
      Es com&uacute;n para futuros padres poner mucho esfuerzo en la
      elecci&oacute;n de nombres masculinos para su beb&eacute;, y con este
      finde utilizar mil m&eacute;todos online y recorrer interminables listas
      de nombres que prometen contener el nombre perfecto para tu hijo pero que
      al final no hacen m&aacute;s que consumir tiempo y someter al lector a los
      mismos nombres una y otra vez.
    </Paragraph>
    <Paragraph>
      Es por esto que muchas veces la herramienta m&aacute;s simple y
      r&aacute;pida termina siendo la m&aacute;s adecuada. En este generador de
      nombres de var&oacute;n al azar encontrar&aacute;s nombres al alcance de
      un toque (o clic) en tan solo un instante, partiendo de la base de miles
      de nombres populares y raros utilizados en muchos pa&iacute;ses (con la
      opci&oacute;n de filtrar por alg&uacute;n pa&iacute;s o idioma en
      concreto).
    </Paragraph>
    <Paragraph>
      No tendr&aacute;s que recorrer grandes listas ni limitarte al top de
      nombres de hombre m&aacute;s populares, simplemente escoge los filtros que
      prefieras y comienza a generar un nombre tras otro sin l&iacute;mite
      alguno, tom&aacute;ndote el tiempo que consideres adecuado para evaluar
      cada uno.
    </Paragraph>
    <Heading3>
      &iquest;Por qu&eacute; la gente necesita generar nombres masculinos
      aleatorios?
    </Heading3>
    <Paragraph>
      Adem&aacute;s de los motivos y situaciones ya planteadas m&aacute;s
      arriba, existen muchos otros casos de uso para un elemento tan
      b&aacute;sico y frecuente como un nombre masculino (o nombre de persona en
      general), y generarlo de forma aleatorio simplemente facilita la tarea de
      elecci&oacute;n.
    </Paragraph>
    <Paragraph>
      En muchos casos, tener a nuestra disposici&oacute;n un generador de
      nombres masculinos en espa&ntilde;ol puede servir para crear historias con
      nombres cre&iacute;bles y comunes pero variados y creativos. En otras
      circunstancias puede ayudarnos a completar formularios y plantillas que
      requieran un nombre de persona, pero no necesariamente el de una personal
      real.
    </Paragraph>
    <Paragraph>
      Existen numerosos juegos de mesa y actividades grupales que requieren de
      la asignaci&oacute;n de nombres, donde una herramienta como esta puede
      aportar nombres originales e interesantes, pero sin alejarse demasiado de
      los usados por las personas en la actualidad.
    </Paragraph>
    <Heading3>Muchas opciones e ideas interesantes en un instante</Heading3>
    <Paragraph>
      Al partir de una lista de miles de nombres de var&oacute;n actualmente
      utilizados en muchos pa&iacute;ses, uno puede f&aacute;cilmente disponer
      de opciones en gran cantidad sin perder la variedad y aleatoriedad.
    </Paragraph>
    <Paragraph>
      Nombres ex&oacute;ticos de hombre mezclados con otros realmente comunes,
      nombres cortos y largos, algunos m&aacute;s antiguos y otros realmente
      actuales y nuevos, entre muchas otras opciones. De forma f&aacute;cil y
      r&aacute;pida, este generador de nombres de hombre al azar te
      permitir&aacute; tener tantos nombres como desees.
    </Paragraph>
    <Heading4>
      Nombres de hombre veros&iacute;miles, pero no personales
    </Heading4>
    <Paragraph>
      Sin importar cu&aacute;l vaya a ser su uso, estos nombres masculinos
      resultar&aacute;n veros&iacute;miles ya que son utilizados en muchos
      pa&iacute;ses, pero no est&aacute;n necesariamente atados a ninguna
      persona f&iacute;sica. Podr&aacute;s, por ejemplo, create cuentas de
      usuario con datos aleatorios y &ldquo;falsos&rdquo; (ficticios, o
      &ldquo;fake&rdquo; en ingl&eacute;s) pero veros&iacute;miles, por lo que
      no har&aacute;n saltar ninguna alarma ni llamar&aacute;n la
      atenci&oacute;n, protegiendo as&iacute; los datos personales que no
      quieras compartir de forma innecesaria.
    </Paragraph>
    <Paragraph>
      Ya sea al crear una cuenta, registrarse en alguna plataforma, crear un
      personaje para un videojuego o relato, proveer informaci&oacute;n de
      contacto que no requiere que sea real, tener nombres de hombre al azar a
      tu disposici&oacute;n puede resultar muy provechoso y conveniente.
    </Paragraph>
    <Heading3>Automatiza tareas repetitivas y tediosas</Heading3>
    <Paragraph>
      Al tener que generar solo uno o dos nombres de ni&ntilde;o al azar puede
      que uno no dude en hacerlo de forma mental y manual, pero cuando la lista
      de nombres requeridos se ampl&iacute;a (incluso con n&uacute;meros tan
      bajos como 20 o 30), comienza a complicarse el evitar sesgos y
      repeticiones, porque nuestro cerebro tiende a recurrir una y otra vez a
      los nombres que nos resulten m&aacute;s familiares y no nos brinda
      demasiadas opciones ni una aleatoriedad real, a diferencia de una
      herramienta de software como este generador de nombres aleatorios de
      hombres.
    </Paragraph>
    <Paragraph>
      Da igual que sean 20, 200 o 2.000 los nombres masculinos que necesites,
      con este generador no te tomar&aacute; m&aacute;s que unos pocos segundos
      (o minutos, a lo sumo) tenerlos a todos. Te ahorrar&aacute;s mucho tiempo,
      esfuerzo y, quiz&aacute;, incluso dolores de cabeza. &iquest;Por
      qu&eacute; malgastar energ&iacute;a en una tarea tan simple como escoger
      elementos al azar?
    </Paragraph>
    <Heading2>
      Una buena experiencia de usuario en cualquier plataforma
    </Heading2>
    <Paragraph>
      Algo a destacar es que, para una tarea tan simple, esta herramienta
      resulta ser a su vez muy sencilla y vers&aacute;til. Podr&aacute;s hacer
      uso de la generaci&oacute;n de nombres al azar desde cualquier plataforma,
      dispositivo y conexi&oacute;n.
    </Paragraph>
    <Paragraph>
      No necesitar&aacute;s malgastar muchos datos descargando cosas de forma
      innecesaria, ni ocupar espacio de almacenamiento instalando una nueva
      aplicaci&oacute;n, ni someterte a un tedioso proceso de registro, ni nada
      similar.
    </Paragraph>
    <Paragraph>
      Esta herramienta est&aacute; planteada para ser tan simple como la
      necesidad que resuelve; despu&eacute;s de todo, ser&iacute;a
      ir&oacute;nico transformar una acci&oacute;n tan b&aacute;sica como la
      elecci&oacute;n de nombres al azar en un proceso aburrido y largo cuando
      lo que buscan sus usuarios es precisamente facilitar la tarea y evitar
      malgastar energ&iacute;a y tiempo.
    </Paragraph>
    <Heading2>
      Extensa lista de nombres de hombre populares y ex&oacute;ticos
    </Heading2>
    <Paragraph>
      Ya lo hemos mencionado, pero volvemos a insistir, este generador de
      nombres masculinos al azar no se limita a utilizar solo los nombres
      m&aacute;s populares, sino que cuenta con miles de nombres disponibles,
      incluyendo los m&aacute;s populares del momento, del a&ntilde;o actual, de
      la &uacute;ltima d&eacute;cada, pero tambi&eacute;n haciendo lugar a
      aquellos m&aacute;s infrecuentes y ex&oacute;ticos, que captan nuestra
      atenci&oacute;n con facilidad dada su rareza.
    </Paragraph>
    <Paragraph>
      Aunque no lo parezca, existen muchos nombres de hombre actualmente en uso,
      los cuales adem&aacute;s los conocemos a la mayor&iacute;a. Lo que sucede
      es que los m&aacute;s populares son normalmente tan frecuentes que parecen
      ser los &uacute;nicos utilizados, es decir, los nombres m&aacute;s comunes
      terminan acaparando un alto porcentaje de la poblaci&oacute;n, y luego en
      la minor&iacute;a restante es en donde encontrar una gran cantidad de
      nombres interesantes y curiosos, que si bien conocemos, son
      dif&iacute;ciles de recordar cuando intentamos intencionalmente pensar en
      nombres infrecuentes o ex&oacute;ticos.
    </Paragraph>
  </Layout>
)

export default NameGeneratorPage

export const pageQuery = graphql`
  query {
    namesJson {
      surnames
      female
      male
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
