import React, { useEffect } from 'react'
import { Stack, Button } from '@chakra-ui/react'

const PersonNameGenerator = ({ setName, setSurname, names, sex }) => {
  const selectRandomWord = someList => {
    const word = someList[Math.floor(Math.random() * someList.length)]
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  const getMaleName = () => {
    setName(selectRandomWord(names.male))
    setSurname(selectRandomWord(names.surnames))
  }

  const getFemaleName = () => {
    setName(selectRandomWord(names.female))
    setSurname(selectRandomWord(names.surnames))
  }

  const getRandomSexName = () => {
    setName(selectRandomWord(names.male.concat(names.female)))
    setSurname(selectRandomWord(names.surnames))
  }

  useEffect(() => {
    if (sex === 'male') {
      setName(selectRandomWord(names.male))
    } else if (sex === 'female') {
      setName(selectRandomWord(names.female))
    } else {
      setName(selectRandomWord(names.male.concat(names.female)))
    }

    setSurname(selectRandomWord(names.surnames))
  }, [setName, setSurname, names, sex])

  let btn1Func
  let btn1Label
  let btn2Func
  let btn2Label
  let btn3Func
  let btn3Label
  if (sex === 'male') {
    btn1Func = getRandomSexName
    btn1Label = 'Aleatorio'
    btn2Func = getMaleName
    btn2Label = 'Hombre'
    btn3Func = getFemaleName
    btn3Label = 'Mujer'
  } else if (sex === 'female') {
    btn1Func = getMaleName
    btn1Label = 'Hombre'
    btn2Func = getFemaleName
    btn2Label = 'Mujer'
    btn3Func = getRandomSexName
    btn3Label = 'Aleatorio'
  } else {
    btn1Func = getMaleName
    btn1Label = 'Hombre'
    btn2Func = getRandomSexName
    btn2Label = 'Aleatorio'
    btn3Func = getFemaleName
    btn3Label = 'Mujer'
  }

  return (
    <Stack spacing={2} direction="row">
      <Button rounded="full" px={6} onClick={() => btn1Func()}>
        {btn1Label}
      </Button>
      <Button
        rounded="full"
        px={6}
        colorScheme="orange"
        bg="orange.400"
        _hover={{ bg: 'orange.500' }}
        onClick={() => btn2Func()}
      >
        {btn2Label}
      </Button>
      <Button rounded="full" px={6} onClick={() => btn3Func()}>
        {btn3Label}
      </Button>
    </Stack>
  )
}
export default PersonNameGenerator
